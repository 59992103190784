<template>
  <form action="" @submit.stop.prevent="() => {}">
    <!-- <b-form-group :class="{ 'mr-4': device === 'desktop', 'pt-3 pr-3': type!=='tabs'}">
      <b-form-radio-group
      v-model="selectedCategory"
      :options="categoryOptions"
      name="radio-sub-category"
      @change="selectingSeriesCategory"
      ></b-form-radio-group>
    </b-form-group> -->

    <div class="form-row" v-if="isOdysseySite" :class="{ 'col-md-12 mt-1': type!=='tabs' }">
      <b-form-group id="input-group-2" class="agent-select" label-for="input-2" :class="type==='tabs' ? 'col-md-3': 'col-md-12'">
        <v-select
          v-model="agent"
          :reduce="agent => agent.value"
          :options="agentList"
          dir="rtl"
          :clearable="false"
          :searchable="true"
          :disabled="loginOdyAgentCode!==changeEnableAgentCode"
        >
        </v-select>
      </b-form-group>
    </div>
    <div class="form-row desktop_form" v-if="type === 'tabs'">
      <div class="form-group col-md-3">
        <Select
          v-if="!isSportPack"
          @changeDestination="changed"
          :type="type"
          @optionSelect="optionSelect"
          :category="currCategory"
          :disabled="disableDestination"
          :hotDestinations="hotDestinations"
        />
        <select-league
          v-else
          @changeSubject="changedLeague"
          :availableSubject="availableSubject"
          :type="type"
          :disabled="disableLeague"
        />
      </div>
      <div class="form-group col-md-3">
        <app-datepicker
          v-if="!isSportPack"
          :availableDates="availableDates"
          :calendarShow="destinationChanged"
          @finalDateSelect="finalDateSelect"
          @dates="chooseDate"
          :destinationText="destinationText"
          :disabled="disableCalendar"
        ></app-datepicker>
        <select-months
          v-else
          @changeMonths="changeMonths"
          :availableDates="availableDates"
          :type="type"
          :disabled="disableMonths"
        />
      </div>
      <div class="form-group col-md-3">
        <occupancy-pax
          @changeBodyCount="changeCount"
          :dropdownShow="dropdownShow"
          :disabled="disableOccupancy"
        />
      </div>
      <div class="form-group col-md-3 d-flex" style="height:3rem;">
        <button
          class="btn flex-fill"
          @click="submitSearch"
          :disabled="searchDisable"
        >
          {{ categoryId ? $t(`search-tab.search_${categoryId}`) : '' }}
          <b-spinner v-if="isLoading"></b-spinner>
          <i class="fas fa-search ml-3" v-else></i>
        </button>
      </div>
    </div>

    <!-- <MobileSearchForm/> -->
    <div class="card card-body boxSideSearch border-0" :class="{ mobile_form: type==='tabs' }">
      <div class="form-row">
        <div class="form-group col-12">
          <Select
          v-if="!isSportPack"
            @changeDestination="changed"
            :type="type"
            @optionSelect="optionSelect"
            :category="currCategory"
            :disabled="disableDestination"
          />
          <select-league
          v-else
          @changeSubject="changedLeague"
          :availableSubject="availableSubject"
          :type="type"
          :disabled="disableLeague"
        />
        </div>
        <div class="form-group col-12">
          <div class="input-group">
            <app-datepicker
              v-if="!isSportPack"
              :availableDates="availableDates"
              :calendarShow="destinationChanged"
              @dates="chooseDate"
              type="side"
              :destinationText="destinationText"
              :disabled="disableCalendar"
            ></app-datepicker>
            <select-months
              v-else
              @changeMonths="changeMonths"
              :availableDates="availableDates"
              :type="type"
              :disabled="disableMonths"
            />
          </div>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countAdult" :disabled="disableOccupancy">
            <template #first>
              <b-form-select-option :value="0" disabled>{{ $t('search-tab.adult') }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countChild" :disabled="disableOccupancy">
            <template #first>
              <b-form-select-option :value="0" disabled>{{ $t('search-tab.child') }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countInfant" :disabled="disableOccupancy">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t('search-tab.infant')
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group d-flex">
          <button
            class="btn flex-fill btn-outline-dark btn-search mobile"
            @click="submitSearch"
            :disabled="searchDisable"
            ref="searchButton"
          >
            {{ categoryId ? $t(`search-tab.search_${categoryId}`): '' }}
            <b-spinner v-if="isLoading" small></b-spinner>
            <i class="fas fa-search" v-else></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from 'vue-select';
import {
  BFormSelect,
  BFormSelectOption,
  BSpinner,
  BFormGroup,
  // BFormRadioGroup,
} from 'bootstrap-vue';
import TemplateSearchTabFH from './TemplateSearchTabFH';

export default {
  name: 'SearchTabFHTheme0',
  components: {
    OccupancyPax: () => import('@/components/atoms/occupancyPax/OccupancyPaxTheme0'),
    Select: () => import('@/components/atoms/searchSelect/SearchSelectTheme0'),
    'app-datepicker': () => import('@/components/atoms/CustomDatepicker'),
    SelectLeague: () => import('@/components/search/atoms/SelectLeague'),
    SelectMonths: () => import('@/components/search/atoms/SelectMonths'),
    BFormSelect,
    BFormSelectOption,
    BSpinner,
    BFormGroup,
    // BFormRadioGroup,
    vSelect,
  },
  extends: TemplateSearchTabFH,
};
</script>

<style scoped>
.form-row[class*=col-]{
  padding-right: 5px;
  padding-left: 5px;
}
.form-group.agent-select {
  margin-bottom: 1rem;
}
@media (max-width: 479px) {
  .mobile_form {
    display: block;
  }
  .desktop_form {
    display: none;
  }
  .btn-search{
    padding-right: 20px;
  }
}
.custom-control {
  padding-right: 1rem;
}
</style>

<style>
.custom-control-input {
  left: auto;
  right: 0;
}
.filter-tab .custom-control-label::before,
.filter-tab .custom-control-label::after {
  right: -1.5rem;
  left: auto;
}
.vs__dropdown-toggle {
  background: #e9ecef !important;
}
.agent-select .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: 3rem;
  cursor: pointer;
}
.filter-tab .vs--disabled .vs__search, .filter-tab .vs--disabled .vs__open-indicator {
  background-color: inherit;
}
.filter-tab .vs--disabled .vs__open-indicator {
  fill: rgba(60,60,60,.2);
}
.filter-tab .vs--disabled.vs--single .vs__selected {
  color: #afafaf;
}
input.vs__search{
  color:#495057
}
.boxSideSearch .custom-select {
  padding: 0.375rem 1.55rem 0.375rem 0.5rem;
}
</style>
